




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ViewLogout extends Vue {
  created() {
    this.$store.commit('setInitialLoader', true)
    localStorage.clear()
    window.location.href = `${window.location.origin}/login`
  }
}
